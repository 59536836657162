import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'
import GiftShowcase from 'src/components/GiftShowcase/GiftShowcase'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import { useLocale } from 'src/lib/I18nContext'
import { GiftsNavigationItemName, Product } from 'src/types'
import {
  getLessonsGiftupProducts,
  getStreamsGiftupProducts,
} from 'src/data/vouchers/gifts'

const MAX_TEXT_WIDTH = 462

export type Props = {
  backgroundType?: 'dark' | 'light'
  product: Product
  header: string
  subheader: string
  cta: string
}

const GiftsHeroContent: React.FC<Props> = ({
  backgroundType,
  product,
  header,
  subheader,
  cta,
}) => {
  const t = useTranslate()
  const locale = useLocale()

  const [gift1, gift2, gift3] =
    product === Product.lessons
      ? getLessonsGiftupProducts(locale, t)
      : getStreamsGiftupProducts(locale, t)

  return (
    <Flex flexDirection="column" alignItems="center" textAlign="center">
      <Box
        as="h1"
        variant="text.h1"
        lineHeight={1.2}
        color={backgroundType === 'dark' ? 'white100' : 'black0'}
        mt={72}
        maxWidth={MAX_TEXT_WIDTH}
        minHeight={{
          _: 150,
          mobile: 96,
        }}
        dangerouslySetInnerHTML={{
          __html: header,
        }}
      />
      <Box
        sx={{
          margin: '48px 0',
          [`@media screen and (max-width: 430px)`]: {
            margin: 0,
            transform: 'scale(0.6)',
          },
          [`@media screen and (max-width: 419px)`]: {
            transform: 'scale(0.5)',
          },
        }}
      >
        <GiftShowcase gifts={[gift1, gift2, gift3]} />
      </Box>
      <Box
        as="p"
        variant="text.paragraph"
        lineHeight={1.6}
        mb="6x"
        maxWidth={MAX_TEXT_WIDTH}
        dangerouslySetInnerHTML={{
          __html: subheader,
        }}
      />
      <Box
        sx={{
          a: {
            textOverflow: 'initial',
          },
        }}
      >
        <Button
          variant={
            product === Product.lessons
              ? BUTTON_VARIANTS.primary
              : BUTTON_VARIANTS.secondary
          }
          label={cta}
          width={{
            _: '100%',
            mobile: 'auto',
          }}
          height="44px"
          href={`#${GiftsNavigationItemName.vouchers}`}
          onClick={(event: Event) => {
            event.preventDefault()
            document
              .getElementById(GiftsNavigationItemName.vouchers)
              ?.scrollIntoView({ behavior: 'smooth' })
          }}
          className="cta-button-vouchers"
          data-l1={locale}
          data-l2="unknown"
        />
      </Box>
    </Flex>
  )
}

export default GiftsHeroContent
